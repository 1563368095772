//
// Breadcrumb
// --------------------------------------------------

.breadcrumb {
  padding: @breadcrumb-padding-vertical 0 ;
  border: none;
  background-color: transparent;
  margin-top: 10px;
  a {
    color: @breadcrumb-color;
  }
  i {
    font-size: @font-size-large;
  }
  & >  li {
    text-shadow: @close-text-shadow;
    padding: 0 (@padding-small-horizontal * 2);
    position: relative;
    white-space: nowrap;

    & + li:before {
      content: '';
      padding: 0;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      right: -5px;
      width: 10px;
      height: 10px;
      border-right: 1px solid @bluejeans-dark;
      border-bottom: 1px solid @bluejeans-dark;
      transform: rotate(-45deg);

    }
  }
}