//
// Alerts
// --------------------------------------------------



// Custom settings
// -------------------------
.alert {
  padding: @alert-padding;
}