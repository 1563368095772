#headermodule {
  border-bottom: 2px solid @navbar-default-bg-hover;
  margin-bottom: 50px;
  margin-top: 50px;
  h3 {
    margin-bottom: 0px;
    text-align: center;
    line-height: 5px;
    font-size: 25px;
    font-family: 'Playfair Display SC', serif;
    font-weight: 900;
    text-transform: uppercase;
    color: @navbar-default-bg-hover;
    span {
      background-color: @body-default-bg-color;
      padding-left: 10px;
      padding-right:10px;
    }
  }
}