//
// Typography
// --------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  color: @darkgray-dark;
}

/* default font size */
.fa {
  font-size: floor((@font-size-base * 1.2));
}

/* Override the bootstrap defaults */
h1 {
  font-size:@font-size-h1;
}
h2 {
  font-size: @font-size-h2;
}
h3 {
  font-size: @font-size-h3;
}
h4 {
  font-size: @font-size-h4;
}
h5 {
  font-size: @font-size-h5;
}
h6 {
  font-size: @font-size-h6;
}