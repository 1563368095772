//
// Buttons
// --------------------------------------------------


// Alternate buttons
// --------------------------------------------------

.btn-primary {
   border-radius: 0px;
   text-transform: uppercase;
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);

}
.btn-primary:hover {
  background-color: @brand-primary;
  border:1px solid @brand-primary;
}

.btn-group {
  button {
    background: @cart-btn-bg;
    .fa {
      color: @white;
    }
  }
  button:hover {
    background: @cart-btn-bg;
    .fa {
      color: @bluejeans-light;
    }
  }
}