//
// Cart
// --------------------------------------------------


.cart {
  margin-right: @navbar-margin-bottom;
  .btn {
    color: @cart-default-color;
    background-color: transparent;
    border-radius: 0px;
    :hover  {
      color:@cart-default-link-color;
    }
  }
  &.open {
    .btn {
      background-color: @cart-btn-bg;
      border: 1px solid @btn-default-border;
      box-shadow: none;
      text-shadow: none;
      color:@cart-default-link-color;

    }
    .dropdown-menu {
      background: @cart-btn-bg;

      &::after {
        content : "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(@cart-bacground-image);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 100%;
        opacity : 0.1;
        z-index: -1;
      }
      z-index: @zindex-dropdown;
      @media (max-width: 478px) {
        width: 100%;
      }
      a {
        color: @cart-default-link-color;
      }
      table {
        margin-bottom: floor((@line-height-computed/2));
        color: @cart-default-color;


      }

      li {
        div {
          min-width: floor((@screen-xs-max * 0.556714472));
          padding: 0 @padding-small-horizontal;

          @media (max-width: 478px) {
            min-width: 100%;
          }
        }
        p {
          margin: @navbar-margin-bottom 0;
          color: @cart-default-color;
        }
      }

    }
    .img-thumbnail{
      background: transparent;
      border-radius: 0px;
      padding: 0px;
    }
  }

}
