//
// Body
// --------------------------------------------------

body {
  font-family: @font-family-sans-serif;
  font-weight: 400;
  color: @text-color;
  font-size: @font-size-base;
  line-height: @line-height-computed;
  width: 100%;
  background: @body-default-bg-color;
}
