/*!
 * Less Theme
 * Copyright 2015 panforest.net
 * Opencart Commercial licence
 */

//
// Load variables and mixins
// --------------------------------------------------

@import "../../../../../../bower_components/bootstrap/less/variables.less";
@import "theme-variables.less";

@import "../../../../../../bower_components/bootstrap/less/mixins.less";
@import "mixins.less";

/* ======== Green Freedom Defaulet  theme ======= */

/** twitter bootrap nav bacgrund turn of*/
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color: transparent;
}

@import "body.less";
@import "typography.less";
@import "links.less";
@import "legend.less";
@import "buttons.less";
@import "wells.less";
@import "dropdowns.less";



.nav-tabs {
  margin-bottom: @navbar-margin-bottom;;
}
.required .control-label:before {
  content: '* ';
  color: darken(@brand-danger, 10%);
  font-weight: bold;
}

/** header */
@import "header.less";
/** top */
@import "top.less";

/* logo */
@import "logo.less";
/* search */
@import "search.less";

/* cart */
@import "cart.less";
/* menu */
@import "menu.less";
/* module*/
@import "module.less";
/* content */
@import "content.less";
/* footer */
@import "footer.less";
/* alert */
@import "alert.less";
/* breadcrumb */
@import "breadcrumb.less";

.pagination {
  margin: 0;
}
/* list group */
@import "list-group.less";
/* product list */
@import "product.less";
/** Owl carousel */
@import "owl.less";

/** Tooltip*/
@import "tooltip.less";


.full-width-slide {
  position: relative;
  top: -148px;
  margin-bottom: -130px !important;
  @media (max-width: 767px) {
    position: relative;
    top: 0px;
    margin-bottom: 20px !important;
  }
}

/* overwrite column width to show 1, 2, or 3 columns*/
#column-left,#column-right  {
  .col-lg-3{
    width: 100%;
  }
  .rating {
    display: none;
  }
}
/** Layout column */
.content {
  &.col-sm-9 {
    .col-lg-3 {
      width: 33.33%;
    }
  }
}

.content {
  // for layout with 3 column
  &.col-sm-6  {
    .col-lg-3 {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  // for layout with 2 column
  &.col-sm-9 {
    .col-lg-3 {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

