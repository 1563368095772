//
// Owl carousel
// --------------------------------------------------

.owl-carousel {
  background-color: @body-default-bg-color;
}

.owl-wrapper-outer {
  border: none;
  box-shadow: none;
}
.owl-controls .owl-page {
  display: none;
}
.owl-carousel .owl-buttons div {
  color: @darkgray-light;
  text-shadow: none;
}