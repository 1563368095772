//
// List group
// --------------------------------------------------

.list-group a {
  border: 1px solid #777;
  color: @list-defualt-font-color;
  background: #dddddd;
  padding: @padding-base-vertical @padding-base-horizontal;
  &.active, &.active:hover, & :hover {
    color: @bluejeans-light;
    background: #3A3A3A;
    border: 1px solid #777777;
  }
}
.list-group-item:first-child {
  border-radius: 0px;
}
.list-group-item:last-child {
  border-radius: 0px;
}
a.list-group-item:hover {
  color: @bluejeans-light;
  text-decoration: none;
  background-color: #3a3a3a;
}
.badge {
  font-weight: normal;
}