//
// Footer
// --------------------------------------------------

footer {
  margin-top: (@line-height-computed * 2);
  padding-top: (@padding-large-vertical * 3);
  background-color: @footer-background-color;

  hr {
    border-top: none;
    border-bottom: 1px solid @pager-border;
  }
  a {
    color: @footer-menu-item-color;
    font-size: (@font-size-base - 1px);
    &:hover {
      color: @brand-primary;
    }
  }
  h5 {
    font-family: @font-family-sans-serif;
    font-size: (@font-size-base );
    font-weight: bold;
    color: @footer-menu-title-color;
    text-transform: uppercase;
  }
  p {
    color: #888888;
  }
}